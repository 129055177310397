/**
 *	iWear - Side bar menu styles
 */

.sidebarContainer {
	background-color: #FFFFFF !important;
	min-height: 100vh;
}

.sidebarLogo {
	display: flex;
	height: 64px;
	margin-bottom: 40px;
	/* background-color: #67A6BF; */
	background-color: #FCFCFC;
	justify-content: center;
	position: relative;
}

.sidebarCollapseContainer {
	position: absolute;
	right: -30px;
	display: flex;
	height: 100%;
	align-items: center;
}

.sidebarCollapseContainer:hover {
	color: #1890FF;
	cursor: pointer;
}

.sidebarMenuItem {
	position: relative;
	padding: 20px 0px 20px 30px;
	overflow: hidden;
	text-overflow: ellipsis;
	color: #FFFFFF;
	/* background-color: #C4C4C4; */
	background-color: #56CFE1;
	cursor: pointer;
}

.sidebarMenuItem:hover {
	/* color: #1890FF !important;
	background-color: #D6E7FF !important; */
	color: #FFFFFF !important;
	background-color: #67A6BF !important;
	cursor: pointer;
}

.sidebarMenuItem:active {
	/* background-color: #C6D7FF; */
	cursor: pointer;
}

.sidebarMenuItemActive {
	/* color: #1890FF !important;
	background-color: #D6E7FF !important; */
	color: #FFFFFF !important;
	background-color: #67A6BF !important;
	cursor: pointer;
}

.sidebarMenuItemOverlay {
	position: absolute;
	top: 0; bottom: 0; left: 0;
	height: 100%;
	/* border-left: 10px solid #1890FF; */
	border-left: 10px solid #47869F;
}
