/**
 *	iWear - Breadcrumb styles
 */

.breadcrumbContainer {
	/* display: flex; */
	align-items: center;
	color: #C4C4C4;
	background-color: #FFFFFF;
	padding: 20px;
	max-width: 100%;
}
