/**
 *	Appointment Contact Panel Component
 */

.contactPanelContainer {
	max-width: 100%;
	max-height: 50px;
	padding: 5px 10px;
	color: #FFFFFF;
	background-color: #1890FF;
	border-radius: 5px;
	margin-bottom: 10px;
}

.contactPanelNameContainer {
	/* width: 20px; */
	height: 100%;
	word-wrap: break-word;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}