/**
 *	Finance - CSS Module
 */

.ramainingContainer {
	padding: 10px 20px !important;
	background-color: #FFFFFF !important;
}

.listModeContainer {
	padding: 20px !important;
	background-color: #FFFFFF !important;
}

/** CHART **/
.cardContainer {
	padding-top: 20px !important;
}

.card {
	padding: 10px !important;
	background-color: #FFFFFF !important;
}

.cardInfo {
	padding: 20px !important;
}

.chartTitleContainer {
	padding: 10px 10px 30px 10px !important;
}

.chartTitle {
	color: #A2A2A2 !important;
}

.cardChart {
	vertical-align: middle !important;
}

.textStroke {
	text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000 !important;
}
