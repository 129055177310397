/**
 *	iWear - Top bar styles
 */

.topBarContainer {
	background-color: #FFFFFF !important;
}

.topBarLogo {
	display: flex;
	max-width: 500px;
	float: left;
}

.topBarDropdownTrigger {
	position: absolute;
	top: 15px;
}

.topBarDropdownMenu {
	top: 50px;
}

.topBarDropdownAccessorInfoContainer {
	max-width: 200px;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.topBarDropdownItem {
	overflow: hidden;
	text-overflow: ellipsis;
}

.topBarDropdownItem:hover {
	overflow: hidden;
	text-overflow: ellipsis;
	color: #1890FF !important;
	background-color: #D6E7FF !important;
}

.topBarDropdownItem:active {
	overflow: hidden;
	text-overflow: ellipsis;
	background-color: #C6D7FF !important;
}

.topBarDropdownItemActive {
	overflow: hidden;
	text-overflow: ellipsis;
	color: #1890FF !important;
	background-color: #D6E7FF !important;
}

.topBarAccessorDropdown {
	background-color: #FFFFFF !important;
	color: #000000 !important;
}

.topBarAccessorDropdown:hover {
	color: #1890FF !important;
	background-color: #D6E7FF !important;
}

.topBarAccessorDropdown:active {
	color: #1890FF !important;
	background-color: #D6E7FF !important;
}

.topBarAccessorDropdownContent {
	color: #000000;
	margin: 0px;
	padding: 5px 12px;
	max-width: 400px;
	overflow: hidden;
	text-overflow: ellipsis;
}
