/**
 *	iWear - To PDF Order styles
 */

.container {
	font-size: 1em !important;
	font-family: 'Prompt' !important;
	padding: 10px 10px !important;
}

.spacing { margin: 8px !important; }
.spacingSmall { margin: 4px !important; }
.spacingLarge { margin: 12px !important; }

.logoContainer {
	height: 90px !important;
	padding-left: 64px !important;
}

.qrCodeContainer {
	float: right;
}

.rxmallRemarkBox {
	width: 100% !important;
	padding: 5px 10px !important;
	color: #000000 !important;
	background-color: #FFFFFF !important;
	border: 2px solid #2E3091 !important;
}

.orderDateBox {
	width: 100% !important;
	line-height: 32px !important;
	padding: 10px !important;
	color: #000000 !important;
	background-color: #FFFFFF !important;
	border: 2px solid #2E3091 !important;
}

.dotFiller {
	position: relative !important;
	margin-top: 0 !important;
	margin-bottom: 0 !important;
}

.dotFiller:before {
	content: '' !important;
	position: absolute !important;
	bottom: .4rem !important;
	width: 100% !important;
	height: 0 !important;
	line-height: 0 !important;
	border-bottom: 2px dotted #000000 !important;
}

.dotContent {
	position: relative !important;
	z-index: 1 !important;
	background-color: #FFFFFF !important;
	padding-right: 0.5rem !important;
}

.radioAlign {
	display: inline-flex !important;
	align-items: center !important;
}

.radioFullwidth {
	width: 100% !important;
}

.labelSize { font-size: 0.65em !important; }

.table {
	width: 100% !important;
	border: 2px solid #2E3091 !important;
	border-collapse: collapse !important;
}

.tableHead {
	/* width: 10% !important; */
	padding-left: 4px !important;
	padding-right: 4px !important;
	font-size: 0.65em !important;
	text-align: center !important;
	color: #FFFFFF !important;
	background-color: #2E3091 !important;
}

.tableBody {
	/* width: 10% !important; */
	padding: 4px !important;
	font-size: 0.55em !important;
	text-align: center !important;
	border: 2px solid #2E3091 !important;
}

.rxPanel {
	width: 100% !important;
	padding: 5px 10px !important;
	color: #FFFFFF !important;
	background-color: #2E3091 !important;
}

.verticalLine {
	position: absolute !important;
	top: 0px !important;
	left: 0px !important;
	border: 1px solid #000000 !important;
	min-height: 100% !important;
}
