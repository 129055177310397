/**
 *	iWear - To PDF Stock styles
 */

.stockPrintExportContainer {
	font-family: 'Prompt' !important;
    font-size: 1em !important;
}

.stockPrintExportTitle {
    display: flex !important;
    justify-content: center !important;
	position: relative !important;
    font-size: 1em !important;
    padding-top: 1em !important;
	padding-bottom: 1em !important;
}

.stockPrintLogo {
	position: absolute !important;
	left: 1em !important;
	display: flex !important;
	align-items: center !important;
	justify-content: center !important;
	z-index: 99 !important;
}

.stockPrintHeader {
    display: flex !important;
    justify-content: space-around !important;
}

.stockPrintHeaderLeftSide {
    flex: 40 !important;
    border-radius: 4px !important;
    border: 1px solid #1c1c1c !important;
    padding: 1em !important;
    margin-left: 20px !important;
}

.stockPrintHeaderRightSide {
    flex: 40 !important;
    border: 1px solid #1c1c1c !important;
    border-radius: 4px !important;
    padding: 1em !important;
    margin-left: 20px !important;
    margin-right: 20px !important;
}

.stockPrintHeaderQrCode {
    display: flex !important;
    justify-content: center !important;
    margin-top: 10px !important;
}

.stockPrintTableContainer {
    margin-top: 20px !important;
}

.stockPrintProductOrder {
    font-weight: 400 !important;
    margin-left: 22px !important;
    margin-top: 20px !important;
}

.stockPrintFooter {
	display: flex !important;
	align-items: center !important;
	justify-content: flex-end !important;
}

.stockPrintBoxSigning {
	width: 300px !important;
	min-height: 150px !important;
	border: 1px solid #1c1c1c !important;
	border-radius: 4px !important;
	margin-top: 20px !important;
    margin-left: 20px !important;
    margin-right: 20px !important;
}

.stockPrintSignField {
	width: 100% !important;
	height: 95px !important;
	border-bottom: 2px dotted #1c1c1c !important;
}

.stockPrintSignLabel {
	display: flex !important;
	align-items: center !important;
	justify-content: center !important;
	text-align: center !important;
	overflow: hidden !important;
	width: 100% !important;
	height: 55px !important;
}
