/**
 *	iWear - Bottom bar menu styles
 */

.bottombarContainer {
	background-color: #FFFFFF !important;
	position: sticky;
	left: 0;
	bottom: 0;
	width: 100%;
	text-align: center;
}
