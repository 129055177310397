/**
 *	Login - CSS Module
 */

.loginAccessButton {
	color: #FFFFFF !important;
}

.loginFontColor  {
	color: #FFFFFF!important;
}

.loginCard {
	border-radius: 4px;
	padding-top: 40px;
	padding-bottom: 40px;
	box-shadow: 2px 2px;
	-webkit-box-shadow: 3px 3px 5px 0px rgba(0, 0, 0, 0.75);
	-moz-box-shadow: 3px 3px 5px 0px rgba(0, 0, 0, 0.75);
	box-shadow: 3px 3px 5px 0px rgba(0, 0, 0, 0.75);
}

.loginBackground {
	background: -moz-linear-gradient(left, rgba(60,59,63,1) 0%, rgba(96,92,60,1) 100%);
	background: -webkit-gradient(left top, right top, color-stop(0%, rgba(60,59,63,1)), color-stop(100%, rgba(96,92,60,1)));
	background: -webkit-linear-gradient(left, rgba(60,59,63,1) 0%, rgba(96,92,60,1) 100%);
	background: -o-linear-gradient(left, rgba(60,59,63,1) 0%, rgba(96,92,60,1) 100%);
	background: -ms-linear-gradient(left, rgba(60,59,63,1) 0%, rgba(96,92,60,1) 100%);
	background: linear-gradient(to right, rgba(60,59,63,1) 0%, rgba(96,92,60,1) 100%);
}
