/**
 *	iWear - Appointment styles
 */

.columnContainer {
	padding: 10px;
}

.columnSeparator {
	border-right: 1px solid #d9d9d9;
}

.columnCategoryContainer {
	max-height: 500px;
	overflow-y: auto;
}

.contactPanelContainer {
	max-width: 100%;
	max-height: 50px;
	padding: 5px 10px;
	color: #FFFFFF;
	background-color: #1890FF;
	border-radius: 5px;
	margin-bottom: 10px;
}

.contactPanelItemSeparator {
	border-left: 1px solid white;
}

.contactPanelNameContainer {
	/* width: 20px; */
	height: 100%;
	word-wrap: break-word;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.contactPanelIconContainer {
	display: flex;
	align-items: center;
	justify-content: center;
}
