/**
 *	Dashboard - CSS Module
 */

.dashboardCardContainer {
	padding-top: 20px;
}

.dashboardCard {
	padding: 10px;
	background-color: #FFFFFF;
}

.dashboardCardWrapper {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 100%;
}
